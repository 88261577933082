import React, {Component, useEffect, useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {required, SimpleForm, TextInput, SaveButton, Toolbar, SelectField, SelectInput} from 'react-admin'
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import request from "../../providers/request";
import Alert from "../Alert";
import getFullName from "../../utils/getFullName";

const ResultUpdateModal = ({showModal, setShowModal, moduleId, userId}) => {
    const styles = makeStyles({
        row: {
            display: 'flex',
            width: '100%',
        },
        rowBottom: {
            display: 'flex',
            width: '100%',
            marginBottom: '25px',
        },
        col4: {
            width: '25%',
            marginRight: '15px',
        },
        col6: {
            width: '50%',
            marginRight: '30px'
        },
        col8: {
            width: '75%',
            marginLeft: '15px',
            marginRight: '30px'
        },
        col12: {
            width: '100%'
        },
        w100: {
            width: '100%'
        },
        error: {
            color: '#f44336',
            fontSize: '0.80rem'
        },
        title: {
            marginBottom: '5px'
        },
        small: {
            fontSize: '0.75rem'
        },
        mt2: {
            marginTop: '5px'
        },
        textSuccess: {
            color: '#28a745'
        }
    })
    const classes = styles()
    const [sending, setSending] = useState(false)
    const [result, setResult] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    /**
     * Статусы.
     * @type {[{name: string, id: string},{name: string, id: string}]}
     */
    const statuses = [
        {
            id: 'success',
            name: 'Сдал'
        },
        {
            id: 'fail',
            name: 'Не сдал'
        }
    ]

    /**
     * Закрывает модалку.
     */
    const closeModal = () => {
        setShowModal(false)
    }

    /**
     * Отправляет данные.
     */
    const sendData = (values) => {
        if (sending) {
            return
        }

        if (values.mark === undefined || values.mark.length === 0) {
            setErrorMessage('Заполните оценку.')
            return
        }

        if (values.status === undefined) {
            setErrorMessage('Выберите статус.')
            return
        }

        if (values.message === undefined || values.message.length === 0) {
            setErrorMessage('Заполните основание.')
            return
        }

        setSending(true)
        request.send('POST', '/api/app/applicant/update-exam', {
            mark: values.mark,
            status: values.status,
            message: values.message,
            userId: userId,
            moduleId: moduleId,
        })
            .then(response => {
                setSending(false)
                setResult(true)
                window.open(response.data.url, '_blank')
                setTimeout(() => {window.location.reload()}, 5000)
            })
            .catch(error => {
                setSending(false)
                setErrorMessage('Возникли ошибки при отправке.')
            })

    }

    /**
     * ToolBar для формы.
     * @param props
     * @return {JSX.Element}
     * @constructor
     */
    const SendToolBar = props => (
        <Toolbar {...props} >
            <SaveButton
                label="Отправить"
                redirect="show"
                disabled={sending}
                submitOnEnter={true}
            />
        </Toolbar>
    )

    return (
        <Dialog open={showModal} onClose={closeModal} maxWidth="md" fullWidth={true}>
            <DialogTitle>Изменить результат экзамена</DialogTitle>
            <DialogContent>
                {result !== null && (
                    <p>Вы успешно изменили результат.</p>
                )}

                {(result === null) && (
                    <SimpleForm save={sendData} toolbar={<SendToolBar />}>
                        <div className={classes.row}>
                            <div className={classes.col6}>
                                <TextInput
                                    fullWidth
                                    required
                                    source="mark"
                                    label="Оценка"
                                />
                            </div>
                            <div className={classes.col6}>
                                <SelectInput
                                    isRequired={true}
                                    source="status"
                                    label="Статус"
                                    optionValue="id"
                                    choices={statuses}
                                    optionText={choice => `${choice.name}`}
                                    fullWidth
                                    InputProps={{ disableUnderline: true }}
                                />
                            </div>
                        </div>
                        <div className={classes.row}>
                            <TextInput
                                className={classes.col6}
                                required
                                source="message"
                                label="Основание для изменения"
                                multiline
                                rows={4}
                            />
                        </div>
                    </SimpleForm>
                )}

                {errorMessage && (
                    <Alert
                        type="error"
                        message={errorMessage}
                        handleClose={() => setErrorMessage('')}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default ResultUpdateModal