import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { TextField, useDataProvider, usePermissions } from 'react-admin'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import ExamApplicationNotification from './ExamApplicationNotification'
import StatusChip from '../profile/StatusChip'
import trimStringNumber from 'utils/trimStringNumber'
import ResultUpdateModal from "./ResultUpdateModal";

const useStyles = makeStyles({
  title: {
    fontSize: 15,
    lineHeight: '18px',
    display: 'block',
    width: '38%'
  },
  summaryWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  button: {
    marginLeft: 'auto',
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)'
  },
  cell: {
    display: 'block',
    marginRight: 15
  },
  info: {
    display: 'inline'
  },
  link: {
    marginLeft: 'auto',
    textDecoration: 'none',
    color: '#c62828'
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.56)'
  }
})

const ExamApplication = ({
  levelId, moduleId, applicantInfo, userId
}) => {
  const classes = useStyles()
  const [load, setLoad] = useState()
  const [groupInfo, setGroupInfo] = useState({})
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const dataProvider = useDataProvider()
  const { status } = applicantInfo
  const isExam = status?.type === 'exam'
  const examInfo = status?.exam
  const examId = status?.exam?.id
  const examDate = examInfo?.examDate ? format(new Date(examInfo.examDate.replace(/-/g, '/')), 'dd MMMM yyyy', { locale: ru }) : ''
  const examTime = examInfo?.examTimeStart ? ` в ${examInfo.examTimeStart.slice(0, -3)}` : ''
  const { permissions: { role } = {} } = usePermissions()

  const getGroupInfo = () => {
    if (examId) dataProvider.getOne(`groups/${examId}`).then(({ data }) => setGroupInfo(data))
  }

  useEffect(() => {
    getGroupInfo()
  }, [examId])

  const city = groupInfo?.location?.city?.name
  const address = groupInfo?.location?.address
  const room = groupInfo?.location?.room
  const lastMessage = applicantInfo?.status?.lastMessage?.name

  const LastMessageComponent = props => {
    if (lastMessage) {
      return <div {...props}>{ trimStringNumber(lastMessage, 30) }</div>
    } else {
      return null
    }
  }

  return (
    <>
      <ExpansionPanel onChange={(e, expanded) => {
        status && setLoad(expanded)
      }} >
        <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
        >
          <div className={classes.summaryWrapper}>
            <TextField
                record={applicantInfo}
                source="name"
                className={classes.title}
            />
            {status ? (
                <>
                  <div className={classes.cell}>
                    <StatusChip status={status.type} data={status.type === 'exam' && `${examDate}${examTime}`} />
                  </div>

                  {isExam === false && (
                      <LastMessageComponent className={classes.cell} />
                  )}

                  {(status.type === 'fail' || status.type === 'empty') && (
                      <Link
                          className={`${classes.link} ${classes.cell}`}
                          to={{
                            pathname: '/groups',
                            search: `filter={"levelId": "${levelId}","moduleId": "${moduleId}", "statusId": "2"}`
                          }}
                      >
                        <Button
                            variant="outlined"
                            color="primary"
                            className={classes.button}
                        >
                          Выбрать дату экзамена
                        </Button>
                      </Link>
                  )}

                  {(role === 'admin' && (status.type === 'success' || status.type === 'fail')) && (
                      <Button variant="outlined" color="primary" className={classes.button} onClick={() => setShowUpdateModal(true)}>
                        Изменить
                      </Button>
                  )}
                </>
            ) : (
                <div className={classes.cell}>
                  <StatusChip status="disable" />
                </div>
            )}
            {isExam && groupInfo && (
                <div>
                  <div style={{ display: 'inline' }}>
                    {city && <Typography className={classes.info}>{city}</Typography>}
                    {address && (
                        <Typography className={classes.info}>
                          ,
                          {' '}
                          {address}
                        </Typography>
                    )}
                    {room && (
                        <Typography className={classes.info}>
                          , ауд.&nbsp;
                          {room}
                        </Typography>
                    )}
                  </div>
                  <LastMessageComponent />
                </div>
            )}
          </div>
        </ExpansionPanelSummary>

        {load && <ExamApplicationNotification userId={userId} moduleId={moduleId} applicantInfo={applicantInfo} status={status} />}

      </ExpansionPanel >

      <ResultUpdateModal
          moduleId={moduleId}
          userId={userId}
          showModal={showUpdateModal}
          setShowModal={setShowUpdateModal} />
    </>
  )
}

export default ExamApplication
