import React from 'react'
import {
  useQueryWithStore, Loading, Error, useAuthenticated
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { PageTitle } from 'common'
import ProfileMainInfo from 'components/profile/ProfileMainInfo'
import StatusChip from 'components/profile/StatusChip'
import ProfileTrainings from 'components/profile/ProfileTrainings'
import ProfileEducations from 'components/profile/ProfileEducations'
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
  subtitle: {
    padding: '24px 34px'
  },
  subtitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  empty: {
    marginTop: 50,
    textAlign: 'center'
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  }
})

const ProfilePage = () => {
  const classes = useStyles()
  useAuthenticated()

  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'applicant/profile'
  })

  if (!loaded) { return <Loading /> }
  if (error) { return <Error /> }

  const {
    email,
    name,
    middleName,
    surname,
    phone,
    applicant
  } = data

  return (
    <>
      {applicant ? (
        <>
          <PageTitle title="Профиль претендента" />
          <ProfileMainInfo
            email={email}
            name={name}
            middleName={middleName}
            surname={surname}
            phone={phone}
            regNumber={applicant.regNumber}
            regionId={applicant.regionId}
            oldRegNumber={applicant.oldRegNumber}
            checkOldRegNumber={applicant.checkOldRegNumber}
            balance={applicant.balance}
            registeredAt={applicant.registeredAt}
            extendYear={applicant.extendYear}
            extendDate={applicant.extendDate}
            expiredDate={applicant.expiredDate}
          />
          <div className={classes.subtitleWrapper}>
            <Typography
              variant="h4"
              className={classes.subtitle}
            >
              Анкета
            </Typography>
            <StatusChip status={applicant.status} />
          </div>
          <ProfileEducations
            educations={applicant.educations}
            foreignEducation={applicant.foreignEducation}
            foreignNumber={applicant.foreignNumber}
          />
          {applicant.trainings.length > 0 && (
            <ProfileTrainings trainings={applicant.trainings} />
          )}
        </>
      ) : (
          <div className={classes.empty}>
            <Typography>Анкета ещё не заполнена</Typography>
            <Link to="/applicant-form">Заполнить</Link>
          </div>
        )}
    </>
  )
}

export default ProfilePage
