import React from 'react'
import { useAuthenticated } from 'react-admin'
import { PageTitle } from 'common'
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 5,
    paddingBottom: 5,
  },

  link: {
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'none',
    fontWeight: "normal"
  }
});

const InfoPage = () => {
  useAuthenticated()

  const classes = useStyles();

  return (
    <>
      <PageTitle title="Полезная информация" />
      <Card className={classes.card}>
        <h3><a className={classes.link} href="https://eak-rus.ru/raspisanie_ekzamenov" target="_blank">Расписание экзаменов на год</a></h3>
        <h3><a className={classes.link} href="https://eak-rus.ru/about_attestation/podgotovka_k_sdache_examena1" target="_blank">Подготовка к сдаче экзаменов</a></h3>
        <h3><a className={classes.link} href="https://eak-rus.ru/normativnye_dokumenty/prikazy_ano_eak" target="_blank">Порядок оплаты</a></h3>
        <h3><a className={classes.link} href="https://eak-rus.ru/news1" target="_blank">Новости ЕАК</a></h3>
        <h3><a className={classes.link} href="https://certificate.eak-rus.ru" target="_blank">О сертификациях ЕАК</a></h3>
      </Card>
    </>
  )
}

export default InfoPage
