import React, { useState, useEffect } from 'react'
import { FormattedDateField } from 'common'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const headCells = [
  { id: 'checkbox', label: '' },
  { id: 'id', label: '' },
  { id: 'name', label: 'ФИО' },
  { id: 'createdAt', label: 'Зарегистрирован' },
  { id: 'payment', label: 'Статус оплаты' },
]

const useStyles = makeStyles({
  delete: {
    cursor: 'pointer',
  },
  textBold: {
    fontWeight: 'bold',
  },
  textBoldDisabled: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.36)'
  },
  title: {
    margin: '15px 0 0 24px',
  },
  subtitle: {
    margin: '8px 0 8px 24px',
  },
  buttonEdit: {
    marginTop: 20,
  },
  button: {
    marginTop: 20,
    marginLeft: 20,
  },
  textRed: {
    color: '#F44336',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  checkbox: {
    marginLeft: 12,
  },
})

const ExamApplicantsTable = ({
  applicants,
  requestApplicantsSort,
  deleteList,
  addList,
  setDeleteList,
  setAddList,
  isEditable
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('createdAt')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(1000)
  const [applicantsList, setApplicantsList] = useState(applicants)
  const [selectAll, setSelectAll] = useState(false)
  const total = applicantsList.length

  useEffect(() => {
    setApplicantsList(applicants)
  }, [applicants])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setPage(0)
    requestApplicantsSort(perPage, page, isAsc ? 'desc' : 'asc', property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    requestApplicantsSort(perPage, newPage, order, orderBy)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(event.target.value)
    setOrder('desc')
    setOrderBy('createdAt')
    setPage(0)
    requestApplicantsSort(event.target.value)
  }

  const isInDeleteList = id => deleteList.some(el => el === id)
  const isInAddList = id => addList.some(el => el === id)

  const handleClickCheckbox = id => {
    const { inGroup } = applicantsList.filter(el => el.application.id === id)[0].application

    applicantsList.forEach(el => {
      if (el.application.id === id) {
        el.application.inGroup = !el.application.inGroup
      }
    })

    if (inGroup && isInAddList(id)) {
      const index = addList.indexOf(id)
      addList.splice(index, 1)
      return setAddList([...addList])
    }
    if (inGroup && !isInAddList(id)) {
      return setDeleteList([...deleteList, id])
    }
    if (!inGroup && !isInDeleteList(id)) {
      return setAddList([...addList, id])
    }
    if (!inGroup && isInDeleteList(id)) {
      const index = deleteList.indexOf(id)
      deleteList.splice(index, 1)
      return setDeleteList([...deleteList])
    }

    return null
  }

  const uniqueList = (value, index, self) => {
    return self.indexOf(value) === index
  }

  /**
   * Выбрать всех, у которых статус заявки оплачен.
   */
  const handleClickAll = () => {
    let list = addList
    applicantsList.forEach((item) => {
      if (item.application.statusId === 4) {
        const id = item.application.id
        item.application.inGroup = true
        list.push(id)
      }
    })
    setAddList(list.filter(uniqueList))
    setSelectAll(!selectAll)
  }

  const EnhancedTableHead = () => {
    const createSortHandler = property => event => {
      handleRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => {
            if (headCell.id === 'checkbox') {
              return <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel>
                  <Checkbox
                    color="primary"
                    checked={selectAll}
                    onClick={() => handleClickAll()}
                  />
                  Все
                </TableSortLabel>
              </TableCell>
            }
            return (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }

  const getRowsApplicants = () => {
    return applicantsList.map((row, index) => {
      const {
        application: {
          id,
          payment,
          createdAt,
          inGroup
        },
        name,
        middleName,
        surname,
      } = row
      if (!isEditable) {
        return null
      }
      return (
        <TableRow
          key={id}
        >
          {isEditable && (
            <TableCell padding="checkbox">
              <Checkbox
                disabled={!payment}
                color="primary"
                checked={inGroup}
                className={classes.checkbox}
                onClick={() => handleClickCheckbox(id)}
              />
            </TableCell>
          )}
          {isEditable && (<TableCell>{index + 1}</TableCell>)}
          <TableCell className={classes[`${payment ? 'textBold' : 'textBoldDisabled'}`]}>{`${surname} ${name} ${middleName}`}</TableCell>
          <TableCell>
            <FormattedDateField date={createdAt} formatting="dd.MM.yyyy" />
          </TableCell>
          <TableCell className={classes[!payment && 'textRed']}>{payment ? 'Оплачен' : 'Не оплачен'}</TableCell>
        </TableRow>
      )
    })
  }

  const ApplicantsTable = () => (
    <>
      <TableContainer>
        <Table>
          <EnhancedTableHead />
          <TableBody>
            {getRowsApplicants()}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 1000]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )

  return (
    <ApplicantsTable />
  )
}

export default ExamApplicantsTable
