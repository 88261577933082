import React from 'react'

import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import {FormattedDateField} from "../../common";

const ExamApplicationNotificationItem = ({ name, createdAt }) => (
  <>
    <TableRow>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell align="right">
          <FormattedDateField date={createdAt} formatting="dd MMMM yyyy, HH:mm" />
      </TableCell>
    </TableRow>
  </>
)

export default ExamApplicationNotificationItem
