import React from 'react'
import {useAuthenticated} from 'react-admin'
import { PageTitle } from 'common'
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  card: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 5,
    paddingBottom: 5
  },

  headerLink: {
    marginBottom: 25
  },

  mt4: {
    marginTop: 10
  },

  mt5: {
    marginTop: 25
  },

  subList: {
    '& li': {
      marginTop: 10,
      marginBottom: 10
    }
  },

  subListToggle: {
    '& li': {
      display: 'none',
      marginTop: 10,
      marginBottom: 10
    }
  },

  link: {
    color: '#c62828',
    fontWeight: "normal"
  },

  button: {
    backgroundColor: '#c62828',
    color: '#fff',
    border: "none",
    padding: 15,
    borderRadius: 4,

    '&:hover': {
      backgroundColor: '#841a1a',
      cursor: 'pointer'
    },

    '&[disabled]': {
      backgroundColor: '#716f6f',
    },

    '&[disabled]:hover': {
      cursor: 'not-allowed'
    }
  },
});

const TrainingPage = () => {
  useAuthenticated()

  const classes = useStyles();

  const toggleListItem = (event) => {
    const display = event.target.nextElementSibling.style.display
    event.target.nextElementSibling.style.display = display === 'block' ? 'none' : 'block'
  }

  return (
    <>
      <PageTitle title="Подготовка к сдаче экзамена" />
      <Card className={classes.card}>
        <ul>
          <li className={classes.headerLink}>
            <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/istoch_inf_na_01_04_2024.docx">
              <b>Источники информации (законодательные и нормативные акты), рекомендуемые для подготовки к сдаче квалификационного экзамена на получение квалификационного аттестата аудитора в III квартале 2024 года</b>
            </a>
          </li>
          <li className={classes.headerLink}>
            <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/progr-nmd-pr_19012024.docx">
              <b>Программа проведения квалификационного экзамена лица, претендующего на получение квалификационного аттестата аудитора</b>
            </a>
          </li>
          <li className={classes.headerLink}>
            <b onClick={toggleListItem}>Типичные ошибки в работах претендентов и рекомендации Методического совета ЕАК по подготовке к экзамену</b>
            <ul className={classes.subList} style={{ display: 'none'}}>
              <li>
                Обобщенный анализ результатов I этапа квалификационного экзамена
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/Системные  ошибки II кв. 2024.pdf">
                      II квартал 2024 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/Системные  ошибки I кв. 2024_.pdf">
                      I квартал 2024 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/sistemnye_oshibki_1_etap_iv_kv_2023.pdf">
                      IV квартал 2023 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/errors_3_2023.pdf">
                      III квартал 2023 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/errors_2_2023.pdf">
                      II квартал 2023 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn_1kv2023.pdf">
                      I квартал 2023 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-4kv2022.pdf">
                      IV квартал 2022 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-3kv2022.pdf">
                      III квартал 2022 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-2kv2022.pdf">
                      II квартал 2022 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-1kv2022.pdf">
                      I квартал 2022 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-4kv2021.pdf">
                      IV квартал 2021 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-3kv2021.pdf">
                      III квартал 2021 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-2kv2021.pdf">
                      II квартал 2021 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-1kv2021.pdf">
                      I квартал 2021 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-4kv2020.pdf">
                      IV квартал 2020 года
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-3kv2020.pdf">
                      III квартал 2020 года
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Обобщенный анализ результатов II этапа квалификационного экзамена
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-summer21.pdf">
                      Летняя сессия 2021
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/sistemn-winter21.pdf">
                      Зимняя сессия 2021
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/typical_errors_summer_2022.pdf">
                      Летняя сессия 2022
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/typical_errors_winter_2022.pdf">
                      Зимняя сессия 2022
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/typical_errors_summer_2023.pdf">
                      Летняя сессия 2023
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/mark/Отчет о типич ошибках_ЗИМА_2023.pdf">
                      Зимняя сессия 2024
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={classes.headerLink}>
            <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/probnoe_testirovanie_1_etapa_ekzamena.docx">
              <b>Пробное тестирование I этапа экзамена</b>
            </a>
          </li>
          <li className={classes.headerLink} style={{display: 'none'}}>
            <a className={classes.link} href="/#/demo">
              <b>Пробное тестирование II этапа экзамена</b>
            </a>
          </li>
          <li className={classes.headerLink}>
            <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/rukovodstvo_po_2et_1.pdf">
              <b>Руководство по использованию программного комплекса «ЕАК» на II этапе экзамена</b>
            </a>
          </li>

          <li>
            <div><b>Материалы к модулям первого этапа квалификационного экзамена</b></div>
            <ul className={classes.mt4}>
              <li>
                Модуль «Основы аудиторской деятельности»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/1/mm-audit1et_100222.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/1/audit-1et-mm.pdf">
                      Методические материалы для подготовки претендентов - Использование ИТ
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/1/audit-1et-mm-iii.pdf">
                      Методические материалы для подготовки претендентов - Использование источников информации
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/1/primer-audit_100222.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Основы бухгалтерского учета»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/2/mm-bu1et_100222.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/2/bu_1et_01042022.pdf">
                      Методические материалы для подготовки претендентов - Использование источников информации
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/2/primer-bu_100222.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Финансы и финансовый анализ»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/3/mm-finans1et_251219.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/3/osn-findeiat_251219.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Основы законодательства Российской Федерации»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/4/mm-pravo1et_251219.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/4/osn-pravodeiat_150120.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Основы налогового законодательства Российской Федерации»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/5/metodicheskie_materialy_po_podgotovke_1_etap_nalogi_itog_1.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/5/osnovy_nalogovogo_zakonodatel_stva_rf_1_etap_primer_bileta_na_sajt_itog.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className={classes.mt5}>
            <div><b>Материалы к модулям второго этапа квалификационного экзамена</b></div>
            <ul className={classes.mt4}>
              <li>
                <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/MM-IT-2etap.pdf">
                  Методические материалы для подготовки претендентов «Информационные технологии в аудиторской деятельности: источники для подготовки и примеры заданий»
                </a>
              </li>
              <li className={classes.mt4}>
                Модуль «Аудиторская деятельность и профессиональные ценности»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/6/audit-2et-mm_2024_25.04.2024.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/6/audit-2et-mm-iii.pdf">
                      Методические материалы для подготовки претендентов - Использование источников информации
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/6/audit-2et-primer_25.04.2024.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Бухгалтерский учет и бухгалтерская (финансовая) отчетность»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/7/bu-2et-mm.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/7/bu_2et_01042022.pdf">
                      Методические материалы для подготовки претендентов - Использование источников информации
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/7/bu-2et-z.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/7/2_et_primer_ekzbilet.pdf">
                      Пример экзаменационного билета-из предыдущих сессий
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Анализ и оценка устойчивости бизнеса»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/8/fin-2et-mm.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/8/fin-2et-z.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/8/fin-2et-pr.pdf">
                      Приложение к примеру экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Управленческий учет, управление рисками, внутренний контроль»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/9/uu-2et-mm.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/9/uu-2et-z.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Правовое регулирование экономической деятельности»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/10/pravo-2et-mm.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/10/pravo-2et-z.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Модуль «Налоги и налоговое администрирование»
                <ul className={classes.subList}>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/11/metod_materialy_po_podgotovke_2_etap_nalogi_itog_3.pdf">
                      Методические материалы для подготовки претендентов
                    </a>
                  </li>
                  <li>
                    <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/11/nalogi_i_nalogovoe_administr_2_etap_primer_bileta_na_sajt_itog.pdf">
                      Пример экзаменационного билета
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li className={classes.mt5}>
            <div><b>Материалы к модулю «Комплексная задача» по третьему этапу квалификационного экзамена</b></div>
            <ul className={classes.subList}>
              <li>
                <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/12/mm-3-k3_2022.pdf">
                  Методические материалы для подготовки претендентов
                </a>
              </li>
              <li>
                <a className={classes.link} target="_blank" href="https://examen.eak-rus.ru:8443/training/12/kz3_2022.pdf">
                  Пример экзаменационного билета
                </a>
              </li>
            </ul>
          </li>
        </ul>

      </Card>
    </>
  )
}

export default TrainingPage
