import React, { useState, useEffect } from 'react'

import { usePermissions, useDataProvider, useNotify } from 'react-admin'
import update from 'immutability-helper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Modal from 'common/modal'

import ApplicationsUserInfo from 'components/applications/ApplicationsUserInfo'
import ApplicationsExam from 'components/applications/ApplicationsExam'
import ApplicationsAuto from 'components/applications/ApplicationsAuto'
import ApplicationsRegistration from 'components/applications/ApplicationsRegistration'
import ApplicationsСertificate from 'components/applications/ApplicationsСertificate'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  actionBar: {
    marginTop: '20px'
  },
  rightAlign: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  payment: {
    marginLeft: 17,
    marginRight: 17
  },
  textArea: {
    width: '400px',
    height: '200px'
  },
  alert: {
    backgroundColor: '#ffd9d9',
    padding: 15,
    borderRadius: 4,
    border: '1px solid #c62828',
    color: '#c62828'
  }
})

const ApplicationsMain = ({
  record: {
    id,
    typeId,
    statusId,
    applicant,
    modules,
    grades,
    ...record
  }, currentStatusId
}) => {
  const classes = useStyles()
  const { permissions: { role } = {} } = usePermissions()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [stateModules, setStateModules] = useState()
  const [cancelReason, setCancelReason] = useState(false)
  const [loading, setLoading] = useState(false)

  const isAdmin = role === 'admin' || role === 'manager'
  const isManager = role === 'managerTraining'

  const currentDate = new Date();
  const examDate = Date.parse(record.group?.examDate)

  useEffect(() => {
    if (modules) {
      setStateModules(modules.map(({ name, id }) => {
        const gradeObj = grades.find(item => item.moduleId === id)
        const grade = gradeObj ? gradeObj.grade : null

        return {
          name,
          id,
          checked: true,
          grade
        }
      }))
    }
  }, [])

  const toggleModules = (ev, moduleId) => {
    const {
      target: {
        checked
      }
    } = ev
    const index = stateModules.findIndex(x => x.id === moduleId)

    setStateModules(update(stateModules, { [index]: { checked: { $set: checked } } }))
  }

  const onGradeChange = (e, moduleId) => {
    let { target: { value } } = e
    value = value.replace(/[^0-9]/g, '')
    const index = stateModules.findIndex(x => x.id === moduleId)
    setStateModules(update(stateModules, { [index]: { grade: { $set: value } } }))
  }

  const approveStatus = async () => {
    setLoading(true)
    const gradesArr = stateModules.reduce((acc, { id: moduleId, grade, checked }) => {
      if (grade && checked) {
        acc.push({
          moduleId,
          grade
        })
      }
      return acc
    }, [])

    const checkedModulesIdList = stateModules.reduce((acc, { id, checked }) => {
      if (checked) acc.push(id)
      return acc
    }, [])

    const response = await dataProvider.update('applications', {
      id,
      data: {
        modulesSelected: checkedModulesIdList,
        statusId: 4,
        grades: gradesArr
      }
    })

    if (response) {
      setLoading(false)
      notify('Заявка подтверждена')
    } else {
      setLoading(false)
      notify('Ошибка: что-то пошло не так, повторите запрос.', 'warning')
    }
  }

  const rejectStatus = async () => {
    setLoading(true)
    const response = await dataProvider.update('applications', {
      id,
      data: {
        statusId: 3,
        cancelReason
      }
    })

    if (response) {
      setLoading(false)
      notify('Заявка отклонена')
    } else {
      setLoading(false)
      notify('Ошибка: что-то пошло не так, повторите запрос.', 'warning')
    }
  }

  const paymentStatus = async () => {
    setLoading(true)
    const response = await dataProvider.update('applications', {
      id,
      data: {
        statusId: 2
      }
    })

    if (response) {
      setLoading(false)
      notify('Заявка переведена в статус "На оплату".')
    } else {
      setLoading(false)
      notify('Ошибка: что-то пошло не так, повторите запрос.', 'warning')
    }
  }

  const handleChange = event => setCancelReason(event.target.value)

  return (
    <>
      {(isAdmin || isManager) && <ApplicationsUserInfo {...applicant} />}
      {
        {
          1: <ApplicationsRegistration documentLink={record.documentLink} {...applicant} />,
          2: <ApplicationsExam
            title="Экзамен"
            applicant={applicant}
            modules={modules}
            statusId={statusId}
            {...record}
          />,
          3: <ApplicationsAuto
            currentStatusId={currentStatusId}
            toggleModules={toggleModules}
            modules={stateModules}
            isAdmin={isAdmin}
            onGradeChange={onGradeChange}
            {...record}
          />,
          4: <ApplicationsСertificate info={record} selectedModules={modules} />
        }[typeId]
      }

      {isAdmin && (
        <Grid container spacing={3} className={classes.actionBar}>
          <Grid item xs={7}>
            <Modal
              trigger={(
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={statusId === 3 || loading}
                >
                  Отклонить
                </Button>
              )}
              send={rejectStatus}
            >
              {record.group && examDate < currentDate && (
                  <div className={classes.alert}>
                    Данный экзамен уже завершён.<br/>
                    Вы действительно хотите отклонить эту заявку?
                  </div>
              )}
              <TextField
                className={classes.textArea}
                label="Введите причину отклонения"
                rows={7}
                multiline
                onChange={handleChange}
              />
            </Modal>
          </Grid>
          <Grid className={classes.rightAlign} item xs={5}>
            <Button
              className={classes.payment}
              variant="outlined"
              color="primary"
              onClick={paymentStatus}
              disabled={statusId === 2 || statusId === 4 || loading}
            >
              На оплату
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={approveStatus}
              disabled={statusId === 4 || loading}
            >
              Принять заявку
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ApplicationsMain
