import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import CustomTextField from 'common/MuiCustomTextField'
import PhoneEdit from 'components/profile/PhoneEdit'
import PasswordChange from 'components/profile/PasswordChange'
import getById from 'utils/getById'
import { useQueryWithStore, useDataProvider } from 'react-admin'
import Button from '@material-ui/core/Button'
import getFullName from 'utils/getFullName'
import downloadFromUrl from 'utils/downloadFromUrl'
import {format} from "date-fns";
import {ru} from "date-fns/locale";

const useStyles = makeStyles({
  paper: {
    padding: 24
  },
  wrapper: {
    display: 'flex'
  },
  email: {
    width: 316,
    marginRight: 20
  },
  fullName: {
    width: 606,
    display: 'block'
  },
  changePassword: {
    display: 'block',
    marginBottom: 20
  },
  city: {
    width: 390,
    marginRight: 20
  },
  regNumber: {
    minWidth: 270
  },
  oldRegNumber: {
    display: 'block',
    width: 406
  },
  balance: {
    width: 196
  },
  expiredField: {
    width: 300,
    marginLeft: 20
  }
})

const ProfileMainInfo = ({
  email,
  name,
  middleName,
  surname,
  phone,
  regNumber,
  regionId,
  oldRegNumber,
  checkOldRegNumber,
  balance,
  registeredAt,
  extendYear,
  extendDate,
  expiredDate
}) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()

  const [passwordFormVisible, setPasswordFormVisible] = useState(false)

  const { data: regions } = useQueryWithStore({
    type: 'getOne',
    resource: 'regions'
  })

  const downloadReceipt = () => {
    dataProvider.getOne('applicant/download-payment')
      .then(({ data: { filename: url } }) => downloadFromUrl(url))
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.wrapper}>
        <CustomTextField
          value={email}
          label="Электронная почта"
          className={classes.email}
        />
        <CustomTextField
          value={regNumber}
          label="Регистрационный номер"
          className={classes.regNumber}
        />
      </div>
      <CustomTextField
        value={getFullName(surname, name, middleName)}
        label="ФИО"
        className={classes.fullName}
      />
      <div className={classes.wrapper}>
        {regions && (
          <CustomTextField
            value={getById(regions, regionId).name}
            label="Место проживания"
            className={classes.city}
          />
        )}
        {balance !== null && (
          <CustomTextField
            value={balance}
            label="Баланс"
            className={classes.balance}
          />
        )}
      </div>
      {!!checkOldRegNumber && (
        <CustomTextField
          value={oldRegNumber}
          label="Старый регистрационный номер"
          className={classes.oldRegNumber}
        />
      )}

      <PhoneEdit phone={phone} />

      {registeredAt !== null && (
        <CustomTextField
          className={classes.field}
          label="Дата регистрации"
          value={format(new Date(registeredAt.replace(/-/g, '/')), 'dd MMMM yyyy', { locale: ru })}
        />
      )}

      {expiredDate !== null && (
        <CustomTextField
          className={classes.expiredField}
          label="Дата окончания срока сдачи экзамена"
          value={format(new Date(expiredDate.replace(/-/g, '/')), 'dd MMMM yyyy', { locale: ru })}
        />
      )}

      {extendYear !== null && extendYear === true && (
        <p>
          Срок сдачи экзамена продлён на 1 год на основании решения АНО "EAK" от {format(new Date(extendDate.replace(/-/g, '/')), 'dd.MM.yyyy', { locale: ru })}
        </p>
      )}

      {passwordFormVisible ? (
        <PasswordChange cb={() => setPasswordFormVisible(false)} />
      ) : (
          <Button
            variant="outlined"
            color="primary"
            className={classes.changePassword}
            onClick={() => setPasswordFormVisible(true)}
          >
            Поменять пароль
          </Button>
        )}
      <Button
        variant="outlined"
        color="primary"
        onClick={downloadReceipt}
      >
        Скачать квитанцию для оплаты
      </Button>
    </Paper>
  )
}

export default ProfileMainInfo
