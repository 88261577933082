import React from 'react'

import { Admin, Resource } from 'react-admin'

import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import eakTheme from 'assets/styles/eakTheme'
import FolderIcon from '@material-ui/icons/Folder'
import PeopleIcon from '@material-ui/icons/People'
import Layout from './layout/Layout'
import Footer from './layout/Footer'
import InfoPage from "./pages/info";
import TrainingPage from "./pages/training";
import DemoPage from "./pages/demo"

import {
  ApplicationsPage,
  ApplicationsShowPage,
  LocationsCreate,
  LocationsEdit,
  LocationsList,
  LoginPage,
  UsersList,
  Dashboard,
  ExamGroupPage,
  ScheduledExamsList,
  GroupsList,
  GroupEdit,
} from './pages'
import { dataProvider, authProvider } from './providers'
import customRoutes from './customRoutes'
import './App.css'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => (
  <>
    <Admin
      authProvider={authProvider}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      layout={Layout}
      loginPage={LoginPage}
      theme={eakTheme}
    >
      {
        ({ role }) => [
          <Resource
            name={['admin', 'manager', 'managerTraining', 'viewer'].indexOf(role) !== -1 ? 'applications' : 'applicant/applications'}
            options={{ label: 'Заявки' }}
            icon={FolderIcon}
            list={ApplicationsPage}
            show={ApplicationsShowPage}
          />,
          <Resource name="users" options={{ label: 'Пользователи' }} icon={PeopleIcon} list={UsersList} />,
          <Resource name="notifications" />,
          <Resource name="user-applications" />,
          <Resource
            name="locations"
            list={LocationsList}
            create={LocationsCreate}
            edit={LocationsEdit}
          />,
          <Resource name="cities" />,
          <Resource name="regions" />,
          <Resource name="exam-levels" />,
          <Resource name="application-types" />,
          <Resource name="application-statuses" />,
          <Resource name="training-centers" />,
          <Resource name="modules" />,
          <Resource name="groups" list={ExamGroupPage} />,
          <Resource name="scheduled-exams" list={ScheduledExamsList} />,
          <Resource name="exam-groups" list={GroupsList} edit={GroupEdit} />,
          <Resource name="info" list={InfoPage} />,
          <Resource name="training" list={TrainingPage} />,
          <Resource name="demo" list={DemoPage} />
        ]
      }
    </Admin>
    <Footer />
  </>
)

export default App
