import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import {
  useDataProvider, useQueryWithStore, useNotify, useRedirect, usePermissions, TextInput,
} from 'react-admin'

import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

import { Form } from 'react-final-form'

import arrayMutators from 'final-form-arrays'

import ApplicantFormSteps from 'components/applicant/ApplicantFormSteps'


const steps = [
  'Претендент',
  'Cведения о высшем профессиональном образовании (как указано в дипломе)',
  'Сведения о подготовке к квалификационному экзамену'
]

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '15px'
  },
  drivers: {
    marginTop: '30px',
    marginBottom: '20px'
  },
  link: {
    marginTop: '30px',
    marginBottom: '30px'
  }
}))

const ApplicantForm = ({ version, id }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { permissions: { role } = {} } = usePermissions()
  const isManager = role === 'managerTraining'
  const isAdmin = role === 'admin' || role === 'manager'

  const [activeStep, setActiveStep] = useState(0)
  const [uploadedFiles, setUploadedFiles] = useState([])

  const [formValues, setFormValues] = useState({
    surname: '',
    educations: [
      {}
    ],
    trainings: []
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (id) {
      setFormValues({ ...formValues, userId: id })
    }
    // eslint-disable-next-line
  }, [])

  const { data: qualifications } = useQueryWithStore({
    type: 'getOne',
    resource: 'qualifications'
  })

  const handleNext = (form, handleSubmit) => {
    const isNotErorrs = Object.keys(form.errors).length == 0

    if (isNotErorrs) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } else {
      handleSubmit()
    }
  }

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1)

  const uploadFile = async (index, file) => {
    let result
    const response = await dataProvider.uploadFile('site/attach', { payload: file })
      .then(({ data: { filename } }) => { result = { index, url: filename } }, error => notify(error.response?.data[0]?.message, 'warning'))
    return result
  }

  const onSubmit = async data => {
    setLoading(true)
    const promiseList = []
    if (uploadedFiles.length) {
      uploadedFiles.forEach(item => {
        const { file, index } = item
        const upload = file instanceof File ? file : file.rawFile
        promiseList.push(uploadFile(index, upload))
      })
    }
    Promise.all(promiseList)
      .then(async results => {
        try {
          // очистить массивы с файлами, которые пришли из формы,
          // чтобы заменить их на ссылки на эти файлы
          data.educations.forEach(edu => {
            if (edu) edu.files = []
          })
          results.forEach(({ index, url }) => {
            data.educations[index].files.push(url)
          })
          const userData = id ? { userId: id, ...data } : data
          await dataProvider.create('applicant/registration', { data: userData })
            .then(() => {
              notify('Данные сохранены')
              setLoading(false)
              if (isManager || isAdmin) {
                redirect('/users')
              } else {
                redirect('/profile')
              }
            })
            .catch(({ message }) => notify(`Ошибка: ${message.data[0].message}`, 'warning'))
        } catch (error) {
          setLoading(false)
          setUploadedFiles([])
          if (error.response) {
            notify(`Ошибка: ${error.response?.data[0]?.message}`, 'warning')
          } else {
            return null
          }
        }
      })
  }

  return (
    <Form
      initialValues={formValues}
      keepDirtyOnReinitialize
      key={version}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Typography variant="h6" gutterBottom>
                    <Box fontWeight="fontWeightBold">{label}</Box>
                  </Typography>
                </StepLabel>
                <StepContent>
                  <ApplicantFormSteps
                    classes={classes}
                    qualifications={qualifications}
                    setFiles={setUploadedFiles}
                    files={uploadedFiles}
                    values={values}
                    step={index}
                  />
                  <>
                    <Divider className={classes.drivers} />
                    <Button
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Назад
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!(values.conset && values.accept && values.personal) || loading}
                      >
                        Отправить Анкету
                      </Button>
                    ) : (
                        <Button
                          color="primary"
                          onClick={() => handleNext(form.getState(), handleSubmit)}
                          variant="contained"
                        >
                          Далее
                        </Button>
                      )}
                  </>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </form>
      )}
    </Form>
  )
}

export default ApplicantForm
