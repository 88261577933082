import React, { useState } from 'react'
import { useMutation, useNotify, useQueryWithStore } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import FormShowProfile from 'components/profile-editing/FormShowProfile'
import FormEditProfile from 'components/profile-editing/FormEditProfile'

const useStyles = makeStyles({
  paper: {
    padding: 24
  },
  wrapper: {
    display: 'flex'
  },
  field: {
    display: 'block',
    width: 283,
    paddingRight: 20,
  },
  fieldLong: {
    display: 'block',
    width: 586,
    paddingRight: 20,
  },
  button: {
    display: 'block',
    marginBottom: 20,
  },
})

const ProfileMainInfoEditable = applicantData => {
  const classes = useStyles()
  const notify = useNotify()
  const [mutate] = useMutation()

  const [isEditable, setIsEditable] = useState(false)
  const [data, setData] = useState(applicantData)

  const { id } = data

  const { data: regions } = useQueryWithStore({
    type: 'getList',
    resource: 'regions'
  })

  const { data: roles } = useQueryWithStore({
    type: 'getList',
    resource: 'roles'
  })

  const { data: managers = [] } = useQueryWithStore({
    type: 'getList',
    resource: 'users',
    payload: {
      pagination: {
        perPage: 1000
      },
      filter: { role: 'managerTraining' }
    }
  })

  const onSubmit = async values => {
    if (values.extendYear && (values.extendDate === undefined || !values.extendDate)) {
      notify('Заполните дату решения.', 'warning')
      return
    }

    mutate({
      type: 'update',
      resource: `applicant/update-account/${id}`,
      payload: { data: values }
    },
      {
        onSuccess: ({
          data: {
            email,
            middleName,
            name,
            phone,
            role,
            surname,
            managerId,
            applicant: {
              regionId,
              regNumber,
              privilege,
              checkOldRegNumber,
              oldRegNumber,
              balance,
              registeredAt,
              extendYear,
              extendDate,
              expiredDate
            }
          }
        }) => {
          setData({
            regionId,
            email,
            id,
            managerId,
            middleName,
            name,
            phone,
            regNumber,
            role,
            surname,
            privilege,
            checkOldRegNumber,
            oldRegNumber,
            balance,
            registeredAt,
            extendYear,
            extendDate,
            expiredDate
          })
          notify('Данные сохранены')
          setIsEditable(false)
        },
        onFailure: error => {
          notify(`Ошибка: ${error.message.data[0].message}`, 'warning')
        }
      })
  }

  return (
    <>
      <Paper className={classes.paper}>
        {isEditable
          ? (
            <FormEditProfile
              regions={regions}
              data={data}
              managers={managers}
              onSubmit={onSubmit}
              roles={roles}
              setIsEditable={setIsEditable}
            />
          ) : (
            <FormShowProfile
              regions={regions}
              data={data}
              managers={managers}
              roles={roles}
              setIsEditable={setIsEditable}
            />
          )}
      </Paper>
    </>
  )
}

export default ProfileMainInfoEditable
